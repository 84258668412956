body {
    color: #494949;
    font-size: 20px;
}

p, li {
    font-size: 1em;
}

.intro {
    padding-top: 5em;
    background-color: #e2ffe3;
}

.container h1 {
    padding-top: 1em;
}

.topscreenshot {
    text-align: center;
}

.topcontainer {
    margin-top: 3em;
}

.navbar-brand {
    font-size: 1.4em;
    padding-top: 1em;
    padding-bottom: 1em;
    height: 3em;
}

.navbar-default {
    background-color: #81C784;
    font-size: 1em;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    color: #FFF;
    background-color: #93c795;
}

.navbar-nav > li > a {
    padding-top: 1.6em;
    padding-bottom: 1.6em;
}

.footer {
    text-align: center;
}

.footer .credits {
    font-size: 0.7em;
}

/*img {
	border-style: solid;
	border-color:  #4a87ee;
	border-width: 1px;
}*/